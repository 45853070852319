<template>
    <div style="background: #FFF">
        <Header/>
        <Banner/>
        <LeadForm/>
    </div>
</template>
<script>
import Header from '../../components/core/Header'
import Banner from '../../components/core/Banner'
import LeadForm from '../../components/simulador/LeadForm.vue'
export default {
    components: {
        Header,
        Banner,
        LeadForm
    }
}
</script>